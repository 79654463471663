import React,{useState,useEffect} from 'react'
import classes from './Card.module.css'
import Box from '../../UI/box/Box'
import db from '../../../firebase'

const Card = ({ accepted,booking }) => {

    const [property, setProperty] = useState({});
    const [user, setUser] = useState({});

    useEffect(() => {
        const checkBooking = async () => {
            //check property
            db.collection("properties")
                .doc(booking.propertyId)
                .get()
                .then((doc) => {
                    if (doc.exists) {
                        // console.log(doc.id, '=>', doc.data());
                        setProperty({ ...doc.data(), propertyDocid: doc.id });
                    } else {
                        setProperty({ propertyDocid: "deleted by the owner" });
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
            //check user
            db.collection("users")
                .doc(booking.userId)
                .get()
                .then((doc) => {
                    if (doc.exists) {
                        // console.log(doc.id, '=>', doc.data());
                        setUser({ ...doc.data(), userDocId: doc.id });
                    } else {
                        setUser({ userDocId: "no user found" });
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        };
        checkBooking();

        return () => { };
    }, []);

    return (
        <Box className={[classes.card,accepted && classes.accepted_card].join(' ')}>
            <h3>Order by {user.name}</h3>
            <p>For property : {property.propertyName}</p>
            <p>Number of person : {booking.NoOfPerson}</p>
            <p>Room Type : {booking.roomType}</p>
            <p>Total price : {booking.totalPrice}</p>
            <p>Booking date : {booking.bookingDate}</p>
            <p>Booking time : {booking.bookingTime}</p>
            <p>Check in date : {booking.hotelingDate.length && booking.hotelingDate[0].checkInDate}</p>
            <p>Check out date : {booking.hotelingDate.length && booking.hotelingDate[1].checkOutDate}</p>
        </Box>
    )
}

export default Card
