import React, { useState, useEffect } from "react";
import "./Booking.css";
import db from "../../firebase";
import BookingCard from "./booking-card/BookingCard";
import Card from "./general-booking-card/Card";
import _ from 'lodash';

const Booking = () => {
    const [bookings, setBookings] = useState([]);

    useEffect(() => {
        db.collection("bookings").onSnapshot((snapshot) =>
            setBookings(snapshot.docs.map((doc) => {

                //if(doc.data().hostId===localStorage.getItem('user'))//only display booking of the current user, 
                return doc.data()
                // else return null;
            }))
        );
        return () => { setBookings([]) };
    }, []);

    const propertyClickHandler = (propertyDocId, userDocId,statusValue) => {

        let status = false;

        db.collection('bookings')
            .onSnapshot((snapshot) =>
                snapshot.docs.map(async (doc) => {
                    if (doc.data().propertyId === propertyDocId && doc.data().userId === userDocId && !status) {
                        status = true;
                        try {
                            await db
                                .collection('bookings')
                                .doc(doc.id).update({
                                    status: statusValue
                                })
                        } catch (e) {
                            alert(e.message);
                        }

                        return;
                    }
                })
            );

    }

    const acceptClickHandler = (propertyDocId, userDocId) => {

        propertyClickHandler(propertyDocId,userDocId,'accepted')
        // const copy = _.cloneDeep(bookings)
        // let i = bookings.findIndex((e) => e.id === id);
        // copy[i].status = "accepted"
        // setBookings(copy)

    };
    const rejectClickHandler = (propertyDocId, userDocId) => {
        var result = window.confirm("Want to reject the booking?");
        if (!result) return;
        propertyClickHandler(propertyDocId,userDocId,'rejected')
    };

    const accptedOrder = bookings.length && bookings.filter((e) => e.status === "accepted").length;
    const rejectedOrders = bookings.length && bookings.filter((e) => e.status === "rejected").length;

    // console.log(bookings);

    return (
        <div className="booking container">
            <div className="booking-content">
                <div className="booking-content__left">
                    <h3 className="heading--secondary booking__total">
                        Total new bookings :{" "}
                        <strong>
                            {bookings.length && bookings.filter((e) => e.status === "pending").length}
                        </strong>
                    </h3>
                    {bookings.length && bookings.map((booking, i) =>
                        booking.status === "pending" ? (
                            <BookingCard
                                acceptClickHandler={acceptClickHandler}
                                rejectClickHandler={rejectClickHandler}
                                newOrder={booking.status === "pending"}
                                key={i}
                                booking={booking}
                            />
                        ) : null
                    )}
                </div>
                <div className="booking-content__right">
                    <h3 className="heading--tertiary booking__total">
                        Total accepted bookings :{" "}
                        <strong>{accptedOrder ? accptedOrder : 0}</strong>
                    </h3>
                    <div className="booking-content__accepted-orders">
                        {bookings.length && bookings.map((booking, i) =>
                            booking.status === "accepted" ? (
                                <Card accepted={true} key={i} booking={booking} />
                            ) : null
                        )}
                    </div>
                    <h3 className="heading--tertiary booking__total">
                        Total rejected bookings :{" "}
                        <strong>{rejectedOrders ? rejectedOrders : 0}</strong>
                    </h3>
                    <div className="booking-content__rejected-orders">
                        {bookings.length && bookings.map((booking, i) =>
                            booking.status === "rejected" ? (
                                <Card key={i} accepted={false} booking={booking} />
                            ) : null
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Booking;
