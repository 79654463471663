import React from "react";
import DayPicker, { DateUtils } from "react-day-picker";
import "react-day-picker/lib/style.css";

export default class Example extends React.Component {
    constructor(props) {
        super(props);
        this.handleDayClick = this.handleDayClick.bind(this);
        this.state = {
            selectedDays: []
        };
    }

    handleDayClick(day, { selected }) {
        const { selectedDays } = this.state;
        if (selected) {
            const selectedIndex = selectedDays.findIndex((selectedDay) =>
                DateUtils.isSameDay(selectedDay, day)
            );
            selectedDays.splice(selectedIndex, 1);
        } else {
            selectedDays.push(day);
            
            
        }
        this.setState({ selectedDays });
        // console.log("this.state.selectedDays >>> ",this.state.selectedDays)
        this.props.dateClickHandler([...this.state.selectedDays])
        // (day, modifiers) => console.log(day, modifiers)
    }

    render() {

        return (
            <div>
                <DayPicker
                    // todayButton="Go to Today"
                    // modifiers={{
                    //     foo: new Date(),
                    // }}
                    selectedDays={this.state.selectedDays}
                    onDayClick={this.handleDayClick}
                    // onTodayButtonClick={(day, modifiers) => console.log(day, modifiers)}
                />
            </div>
        );
    }
}