import React, { useEffect, useState } from "react";
import classes from "./BookingCard.module.css";

import Box from "../../UI/box/Box";
import { IconButton } from "@material-ui/core";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import DoneOutlinedIcon from "@material-ui/icons/DoneOutlined";
import db from "../../../firebase";
import {
    capitalizeString,
    getPropertyById,
    getUserById,
} from "../../../util/methods";

const BookingCard = ({
    newOrder,
    booking,
    acceptClickHandler,
    rejectClickHandler,
}) => {
    // console.log('booking >>> ', booking)
    const [property, setProperty] = useState({});
    const [user, setUser] = useState({});

    useEffect(() => {
        const checkBooking = async () => {
            //check property
            db.collection("properties")
                .doc(booking.propertyId)
                .get()
                .then((doc) => {
                    if (doc.exists) {
                        // console.log(doc.id, '=>', doc.data());
                        setProperty({ ...doc.data(), propertyDocid: doc.id });
                    } else {
                        setProperty({ propertyDocid: "deleted by the owner" });
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
            //check user
            db.collection("users")
                .doc(booking.userId)
                .get()
                .then((doc) => {
                    if (doc.exists) {
                        // console.log(doc.id, '=>', doc.data());
                        setUser({ ...doc.data(), userDocId: doc.id });
                    } else {
                        setUser({ userDocId: "no user found" });
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        };
        checkBooking();

        return () => { };
    }, []);

    const rejectClick = () => {
        rejectClickHandler(property.propertyDocid, user.userDocId);
    };
    const acceptClick = () => {
        acceptClickHandler(property.propertyDocid, user.userDocId);
    };

    // console.log('propertyId >>> ', property.pid)
    // console.log('userId >>> ', property.uid)
    return (
        <Box className={classes.booking_card}>
            <h3>Order by {user.name}</h3>
            <div className={classes.booking_card_content}>
                <div>
                    <p>For property : {capitalizeString(property.propertyName)}</p>
                    <p>Number of person : {booking.NoOfPerson}</p>
                    <p>Room Type : {booking.roomType}</p>
                    <p>Total price : {booking.totalPrice}</p>
                </div>
                <div>
                    <p>Booking date : {booking.bookingDate}</p>
                    <p>Booking time : {booking.bookingTime}</p>
                    <p>
                        Check in date :{" "}
                        {booking.hotelingDate.length && booking.hotelingDate[0].checkInDate}
                    </p>
                    <p>
                        Check out date :{" "}
                        {booking.hotelingDate.length &&
                            booking.hotelingDate[1].checkOutDate}
                    </p>
                </div>
            </div>
            {newOrder && (
                <>
                    <IconButton onClick={rejectClick}>
                        {" "}
                        <CancelOutlinedIcon fontSize="large" />
                        <span
                            style={{
                                color: "rgb(212, 37, 37)",
                                fontSize: "1.8rem",
                                marginLeft: ".5rem",
                            }}
                        >
                            Reject
            </span>
                    </IconButton>
                    <IconButton onClick={acceptClick}>
                        {" "}
                        <DoneOutlinedIcon fontSize="large" />
                        <span
                            style={{
                                color: "rgb(27, 204, 86)",
                                fontSize: "1.8rem",
                                marginLeft: ".5rem",
                            }}
                        >
                            Accept
            </span>
                    </IconButton>
                </>
            )}
        </Box>
    );
};

export default BookingCard;
