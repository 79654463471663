import React, { useEffect, useState } from "react";
import "./UserProperties.css";
import db, { databasePropertyName } from "../../firebase";
import Hr from "../UI/hr/Hr";
import Properties from "./properties/Properties";

const UserProperties = () => {
    const [userProperties, setUserProperties] = useState([]);

    useEffect(() => {
        // Get current user
        // console.log("user check>>>>",localStorage.getItem('user'))
        let userId = localStorage.getItem("userId");
        async function fetchData() {
            // db.collection(databaseUserName)
            //     .doc(userId)
            //     .collection(databasePropertyName)
            //     .onSnapshot((snapshot) =>
            //         setUserProperties(snapshot.docs.map((doc) => doc.data()))
            //     );
            db.collection(databasePropertyName).where('hostId', '==', userId).onSnapshot(snapshot => {
                setUserProperties(snapshot.docs.map((doc) => doc.data()))
            })
        }
        fetchData();
        return () => {
            setUserProperties(null);
        };
    }, []);

    // console.log(userProperties);
    return (
        <div className="user-properties container">
            <h1>Registerd property</h1>
            <Hr className="user-properties-hr" />
            <div className="user-properties__box">
                {userProperties && userProperties.length !== 0 ? (
                    <Properties userProperties={userProperties} />
                ) : (
                        <h1 className="user-properties__no-property">
                            This user has no registered property yet
                        </h1>
                    )}
            </div>
        </div>
    );
};

export default UserProperties;
