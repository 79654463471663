import _ from 'lodash'
import db from '../firebase'

export const isInt = (value) => !isNaN(value) && !hasSpace(value) && !isEmpty(value);

export const hasSpace = str => /\s/.test(str);

export const isEmpty = (str) => (!str || 0 === str.length);

export const capitalizeString = str => _.startCase(_.camelCase(str))

export const getUserById = async (userId) => {
    //check user
    db.collection('users').doc(userId).get()
        .then(doc => {
            if (doc.exists) {
                // console.log(doc.id, '=>', doc.data());
                return { ...doc.data(), uid: doc.id }
            }
            else {
                return { userDocId: 'no user found' }
            }
        })
        .catch(err => {
            console.log(err);
            return false
        });
}

export const getPropertyById = async (propertyId) => {
    //check user
    db.collection('properties').doc(propertyId).get()
        .then(doc => {
            if (doc.exists) {
                // console.log(doc.id, '=>', doc.data());
                return { ...doc.data(), propertyDocId: doc.id }
            }
            else {
                return { propertyDocId: 'deleted by the owner' }
            }
        })
        .catch(err => {
            console.log(err);
            return false
        });
}



export const time = () => new Date().toLocaleTimeString(); // 11:18:48 AM

export const date = () => new Date().toLocaleDateString(); // 11/16/2015

export const dateTime = () => new Date().toLocaleString(); // 11/16/2015, 11:18:48 PM