import React, { useState, useEffect } from "react";
import "./Preview.css";
import { useParams } from "react-router-dom";
import db, { databasePropertyName } from "../../../firebase";
import Slider from "./slider/Slider";
import { capitalizeString } from '../../../util/methods'
import Box from '../../UI/box/Box'
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Hr from '../../UI/hr/Hr'
import Button from '../../UI/button/Button'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import {useHistory} from 'react-router-dom'
import {IconButton} from '@material-ui/core'

const Preview = () => {
    const { propertyId } = useParams();
    const [property, setProperty] = useState([]);
    // console.log(useParams())
    const history = useHistory()
    useEffect(() => {

        if (propertyId) {

            db.collection(databasePropertyName)
                .where("propertyName", "==", propertyId)
                .onSnapshot(snapshot => setProperty(snapshot.docs.map((doc) => (doc.data()))));//returns array

        }
        return () => {
            setProperty([])
        }
    }, [])

    // property.length && console.log(property[0])


    return (
        <div className="preview__property">
            {/* <header className="preview__header"><h1>Preview Header</h1> </header> */}
            {property.length &&
                <div className="container property-main">
                    <IconButton style={{borderRadius:'.5rem'}} onClick={()=> history.push('/dashboard')}><ArrowBackIosIcon fontSize="large" /> Back</IconButton>
                    <h1>{`${capitalizeString(property[0]?.propertyName)}, ${(property[0]?.propertyLocation?.city)}`}</h1>
                    <div className="property__stats-boxes">
                        <Box className="property__stats-box">
                            <div className="property__stats-box-left">
                                <p>100 booking complete</p>
                                <p>3.5 Rating</p>
                                <p>Overall score</p>
                            </div>
                            <CheckCircleIcon style={{ fontSize: '4.4rem', color: '#458FF6' }} />
                        </Box>
                        <Box className="property__stats-box">
                            <div className="property__stats-box-left">
                                <h4>PKR 750,000</h4>
                                <span>Earned this month</span>
                            </div>
                            <TrendingUpIcon style={{ fontSize: '5.2rem', color: '#458FF6' }} />
                        </Box>
                        <Box className="property__stats-box">
                            <div className="property__stats-box-left">
                                <h4>PKR 750,000</h4>
                            </div>
                            <VisibilityIcon style={{ fontSize: '4.4rem', color: '#458FF6' }} />
                        </Box>
                    </div>
                    <Box className="preview-main__properties">
                        <div className="preview-main__photos">
                            <h3 className="heading--tertiary">Staus</h3>
                            {!property[0].publish ? (
                                <h1 className="preview-property__text preview-property__pending">PENDING</h1>) : (
                                    <h1 className="preview-property__text preview-property__published">Published</h1>
                                )}
                        </div>
                        <Hr className="preview__property-hr" />
                        <div className="preview-main__photos">
                            <h3 className="heading--tertiary">PHOTOS</h3>
                            {property[0]?.propertyImagesURL?.length && <Slider images={property[0]?.propertyImagesURL} />}
                        </div>
                        <Hr className="preview__property-hr" />
                        <div className="preview-main__rooms">
                            <h3 className="heading--tertiary">Rooms Details</h3>
                            <ul>
                                <li className="text">Available Rooms : {property[0]?.availableRooms}</li>
                                <li className="text">Bedrooms : {property[0]?.bedrooms}</li>
                                <li className="text">Room types : {property[0]?.privateRooms}</li>
                                <li className="text">The property can easily accommodate : {property[0].guests} guests</li>
                                <li className="text">Stay Type : {property[0]?.stayType}</li>
                            </ul>
                        </div>
                        <Hr className="preview__property-hr" />
                        <div className="preview-main__amenities">
                            <h3 className="heading--tertiary">Amenities</h3>
                            <div className="preview-main__amenities-boxes">
                                <ul className="preview__property-list">
                                    {property[0]?.basicFacilities?.map((fac, i) => <li key={i}>{fac.name}</li>)}
                                </ul>
                                <ul className="preview__property-list">
                                    {property[0]?.advanceFacilities?.map((fac, i) => <li key={i}>{fac.name}</li>)}
                                </ul>
                            </div>
                        </div>
                        <Hr className="preview__property-hr" />
                        <div className="preview-main__description">
                            <h3 className="heading--tertiary">Description</h3>
                            <p className="text" style={{ marginTop: '1rem' }}>{property[0]?.propertyDescription}</p>
                        </div>
                        <Hr className="preview__property-hr" />
                        <div className="preview-main__propertyType">
                            <h3 className="heading--tertiary">Property Type</h3>
                            <p className="text" style={{ marginTop: '1rem' }}>{property[0]?.propertyCategory}</p>
                            <p className="text" style={{ marginTop: '.5rem' }}>Listing type : {capitalizeString(property[0].propertyType)}</p>
                        </div>
                        <Hr className="preview__property-hr" />
                        <div className="preview-main__location">
                            <h3 className="heading--tertiary">Location (Address)</h3>
                            <p className="text" style={{ marginTop: '1rem' }}>{`
                            ${property[0]?.propertyLocation?.street},  
                            ${property[0]?.propertyLocation?.city}, 
                            ${property[0]?.propertyLocation?.province}
                            `}</p>
                        </div>
                        <Hr className="preview__property-hr" />
                        <div className="preview-main__rules">
                            <h3 className="heading--tertiary">Property Rules</h3>
                            <ul className="preview__property-list" style={{ marginTop: '1rem' }}>
                                {property[0]?.rules?.map((r, i) => <li key={i}>{r.name}</li>)}
                            </ul>
                        </div>
                        <Hr className="preview__property-hr" />
                        <div className="preview-main__rules">
                            <h3 className="heading--tertiary">Property Requirement</h3>
                            <ul className="preview__property-list" style={{ marginTop: '1rem' }}>
                                {property[0]?.propertyRequirement?.map((r, i) => <li key={i}>{r.name}</li>)}
                            </ul>
                        </div>
                        <Hr className="preview__property-hr" />
                        <div className="preview-main__pricing">
                            <h3 className="heading--tertiary">Pricing (in rupees)</h3>
                            <h4 style={{ marginTop: '1rem' }}>Single Bed Pricing</h4>
                            <p className="text" style={{ marginTop: '.5rem' }}>{`Base : ${property[0]?.singleBedPricing?.base}`}</p>
                            <p className="text" style={{ marginTop: '.5rem' }}>{`Min : ${property[0]?.singleBedPricing?.min}`}</p>
                            <p className="text" style={{ marginTop: '.5rem' }}>{`Max : ${property[0]?.singleBedPricing?.max}`}</p>
                            <h4 style={{ marginTop: '1rem' }}>Double Bed Pricing</h4>
                            <p className="text" style={{ marginTop: '.5rem' }}>{`Base : ${property[0]?.doubleBedPricing?.base}`}</p>
                            <p className="text" style={{ marginTop: '.5rem' }}>{`Min : ${property[0]?.doubleBedPricing?.min}`}</p>
                            <p className="text" style={{ marginTop: '.5rem' }}>{`Max : ${property[0]?.doubleBedPricing?.max}`}</p>
                            <h4 style={{ marginTop: '1rem' }}>Two Double Bed Pricing</h4>
                            <p className="text" style={{ marginTop: '.5rem' }}>{`Base : ${property[0]?.twoDoubleBedPricing?.base}`}</p>
                            <p className="text" style={{ marginTop: '.5rem' }}>{`Min : ${property[0]?.twoDoubleBedPricing?.min}`}</p>
                            <p className="text" style={{ marginTop: '.5rem' }}>{`Max : ${property[0]?.twoDoubleBedPricing?.max}`}</p>
                        </div>            
                        <Hr className="preview__property-hr" />
                        <div className="preview-main__booking-details">
                            <h3 className="heading--tertiary">Booking Details</h3>
                            <p className="text" style={{ marginTop: '1rem' }}>How much in advance can guests book? : {property[0]?.advanceBooking}</p>
                            <p className="text" style={{ marginTop: '.5rem' }}>How long can guests stay? maximum stay : {property[0]?.advanceBookingMaxStay}</p>
                            <p className="text" style={{ marginTop: '.5rem' }}>How long can guests stay? minimum stay : {property[0]?.advanceBookingMinStay}</p>
                            <p className="text" style={{ marginTop: '.5rem' }}>Guest before arriving notice time : {property[0]?.guestBeforeArrivingNotice}</p>
                            <p className="text" style={{ marginTop: '.5rem' }}>Have you hosted your property online before? : {property[0]?.onlineStatusOfProperty}</p>
                            <h4 style={{ marginTop: '1rem' }}>Check in time</h4>
                            <p className="text" style={{ marginTop: '.5rem' }}>from {property[0]?.checkInTime?.start} to {property[0]?.checkInTime?.end}</p>
                        </div>
                        <Hr className="preview__property-hr" />
                        <div className="preview-main__special-offer">
                            <h3 className="heading--tertiary">Special offer</h3>
                            <p className="text" style={{ marginTop: '1rem' }}>{property[0]?.specialOffer}</p>                            
                        </div>
                        <Hr className="preview__property-hr" />
                        <div className="preview-main__special-offer">
                            <h3 className="heading--tertiary">Discount</h3>
                            <p className="text" style={{ marginTop: '1rem' }}>Monthly discount : {property[0]?.discount?.monthlyStay}%</p>                            
                            <p className="text" style={{ marginTop: '1rem' }}>Weekly discount : {property[0]?.discount?.weeklyStay}%</p>                            
                        </div>
                        <Hr className="preview__property-hr" />
                    </Box>
                    {/* check if the property has imgaes then show th slider */}
                </div>
            }
        </div>
    );
};

export default Preview;
