import React, { useState } from 'react'
import { InputAnimated } from '../UI/input/Input'
import Button from '../UI/button/Button'
import { auth } from '../../firebase'
import Logo from '../logo/Logo'
import './ResetPassword.css'
import { Link } from 'react-router-dom'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const ResetPassword = () => {

    const [email, setEmail] = useState("");
    const [emailHasBeenSent, setEmailHasBeenSent] = useState(false);
    const [error, setError] = useState(null);

    const sendResetEmail = (event) => {
        event.preventDefault();
        auth
            .sendPasswordResetEmail(email)
            .then(() => {
                setEmailHasBeenSent(true);
                setTimeout(() => {
                    setEmailHasBeenSent(false);
                }, 5000);
            })
            .catch((e) => {
                // console.log(e);
                alert(e.message)
                setError(e.message);
            });
    };

    const emailChangeHandler = (e) =>{
        setEmail(e.target.value)
        if(email.length===0){
            setError(null)
        }
    }
    
    return (
        <div className="reset-password__page">
            <div className="reset-password-box">
                <Logo className="login-page__logo" />
                <h2 className="heading--tertiary login-page__heading">Reset Password</h2>
                {emailHasBeenSent && <p className="reset-password__already-sent">An email has been sent to you!</p>}
                {error !== null && <p className="reset-password__error">ERROR Reseting the password</p>}

                <div className="reset-password__input-box">
                    <InputAnimated value={email} onChange={emailChangeHandler} label="Enter Email " />
                </div>
                <div className="reset-password__btn-box">
                    <div style={{display:"flex",justifyContent:'center',alignItems:'center'}}>
                        <ArrowBackIcon color="primary" fontSize="large" /><p><Link to="/login">Back to Login</Link></p>
                    </div>
                    {email ?
                        <Button disabled={false} onClick={sendResetEmail} className="reset-password__btn">Send me a reset link</Button> :
                        <Button disabled={true} className="reset-password__btn">Send me a reset link</Button>}
                </div>
            </div>
        </div>
    )
}

export default ResetPassword
