import React from 'react'
import classes from './Slider.module.css'

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';


const Slider = ({ images }) => {
    return (
        <div className={classes.slider}>
            <Carousel
                additionalTransfrom={0}
                arrows
                autoPlaySpeed={3000}
                centerMode={false}
                className=""
                containerClass="container-with-dots"
                dotListClass=""
                draggable
                focusOnSelect={false}
                infinite
                itemClass=""
                keyBoardControl
                minimumTouchDrag={80}
                renderButtonGroupOutside={false}
                renderDotsOutside={false}
                responsive={{
                    desktop: {
                        breakpoint: {
                            max: 3000,
                            min: 1024
                        },
                        items: 3,
                        partialVisibilityGutter: 40
                    },
                    mobile: {
                        breakpoint: {
                            max: 464,
                            min: 0
                        },
                        items: 1,
                        partialVisibilityGutter: 5
                    },
                    tablet: {
                        breakpoint: {
                            max:1024,
                            min: 464
                        },
                        items: 2,
                        partialVisibilityGutter: 30
                    }
                }}
                showDots={false}
                sliderClass=""
                slidesToSlide={1}
                swipeable
            >
                {images.map((img, i) => <div key={i} className={classes.slider_item}>
                    <img src={img} alt={img || 'img'} />
                </div>)

                }
            </Carousel>
        </div>
    )
}

export default Slider
