import React from 'react'
import './Help.css'

const Help = () => {
    return (
        <div className="help container">
          <h1>Help page</h1>  
        </div>
    )
}

export default Help
