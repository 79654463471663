import React, { useEffect, useState } from "react";
import classes from "./Properties.module.css";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { IconButton } from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import db, { databasePropertyName } from "../../../firebase";

import { capitalizeString } from "../../../util/methods";
import { useHistory } from "react-router-dom";

const tableHeading = ["Name", "No. Rooms", "Bedrooms", "Location", "Edit"];
const menuStyle = {
    maxHeight: "20rem",
    minWidth: "10rem",
    boxShadow: "none",
};

const Properties = (props) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [propertyName, setPropertyName] = useState('')
    const history = useHistory()

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const rowClickHandler = (e, property) => {
        // const { propertyName } = property;
        setPropertyName(property.propertyName)
        // console.log("property >>> ", property);
        // console.log("propertyName >>> ",propertyName)
        handleClick(e);
    };

    const updateHandler =()=>{
        console.log("property >>>",propertyName)
        handleClose();
        localStorage.setItem('update', true)
        localStorage.setItem('name', propertyName)
        history.push("/register/page101")
    }
    const previewHandler =()=>{
        handleClose()
        history.push(`/preview-property/${propertyName}`)
        // alert("previewHandler clicked")
        // history.push("/my-properties/p:id")
    }

    const deleteHandler = () => {
        let userId = localStorage.getItem("userId");
        let status = false;
        handleClose();
        db.collection(databasePropertyName)
            .onSnapshot((snapshot) =>
                snapshot.docs.map(async (doc) => {
                    if (doc.data().propertyName === propertyName && doc.data().hostId === userId && !status) {
                        status = true;
                        var result = window.confirm("Want to delete?");
                        if (!result) return;

                        try {
                            await db
                                .collection(databasePropertyName)
                                .doc(doc.id)
                                .delete();
                        } catch (e) {
                            alert(e.message);
                        }

                        return;
                    }
                    // console.log(doc.data().propertyName===propertyName)
                })
            );
    };

    useEffect(() => {
        return () => { };
    }, []);

    return (
        <div className={classes.properties} style={{ overflowX: "auto" }}>
            <table className={classes.properties_table}>
                <thead>
                    <tr>
                        {tableHeading.map((th) => (
                            <th key={th}>{th}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {props.userProperties.map((property, i) => (
                        <tr key={i}>
                            <td>{capitalizeString(property.propertyName)}</td>
                            <td>{property.availableRooms}</td>
                            <td>{property.bedrooms}</td>
                            <td>
                                {property.propertyLocation
                                    ? property.propertyLocation?.city +
                                    ", " +
                                    property.propertyLocation?.street
                                    : "Not specified"}
                            </td>
                            <td>
                                <IconButton onClick={(e) => rowClickHandler(e, property)}>
                                    <MoreHorizIcon fontSize="large" />
                                </IconButton>
                            </td>
                            <Menu
                                id="simple-menu"
                                className={classes.menu}
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                                PaperProps={{
                                    style: menuStyle,
                                }}
                            >
                                <MenuItem onClick={() => updateHandler(property)}>Edit</MenuItem>
                                <MenuItem onClick={previewHandler}>Preview</MenuItem>
                                <MenuItem onClick={() => deleteHandler(property)}>Delete</MenuItem>
                            </Menu>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default Properties;
