import React, { useState } from "react";
import "./Header.css";
import { IconButton } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import SettingsIcon from '@material-ui/icons/Settings';
import { useHistory } from "react-router-dom";
import Button, { ButtonSecondary } from '../UI/button/Button'
import Logo from '../logo/Logo'

const Header = () => {
    const history = useHistory();
    // const location = useLocation();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const [showMenu, setShowMenu] = useState(false);
    const toggleClickHandler = () => {
        // console.log("clicked");
        setShowMenu(!showMenu);
    };

    let iconStyles = {
        fontSize: '3rem'
    };

    const [links, setLinks] = useState([
        { name: "Home", click: true, link: "dashboard" }, //home will render as default after login
        // { name: "Messages", click: false, link: "chat" },
        { name: "Bookings", click: false, link: "bookings" },
        { name: "Calendar", click: false, link: "calendar" },
        { name: "My Properties", click: false, link: "my-properties" },
        { name: "Help", click: false, link: "help" }
    ]);

    // useEffect(() => {
    //     history.push(localStorage.getItem('link'))
    // }, [history.location.pathname]);

    const logoutHandler = () => {
        // logout()
        handleClose()
        // Remove current user
        localStorage.removeItem('userId')
        // localStorage.removeItem('docId')
        localStorage.removeItem('update')
        localStorage.removeItem('propertyType')
        localStorage.removeItem('name')


        history.push("/")
    }
    const addListinghandler = () => {
        localStorage.setItem('update', false)
        history.push("/register/page101")
    }
    const updateListinghandler = () => {
        handleClose()
        localStorage.setItem('update', true)
        history.push("/register/page101")
    }
    const linkClickhandler = (i) => {
        // console.log("Link name >>> ", links[i].link);
        // console.log(history);
        // localStorage.setItem('link',links[i].link)

        history.push(links[i].link);
        //first unselect all
        const copy1 = [...links.map((ele) => (ele.click = false))];
        setLinks(copy1);

        //then select the clicked one
        const copy = [...links];
        copy[i].click = true;
        setLinks(copy);
        //console.log(" >>> ",i)
        setShowMenu(false)
    };

    return (
        <header className="header">
            <nav className="navigation container">
                <Logo />
                <ul
                    className={[
                        "navigation__items",
                        showMenu && "navigation__items-show"
                    ].join(" ")}
                >
                    {links.map((link, i) => (
                        <li
                            key={i}
                            onClick={() => linkClickhandler(i)}
                            className={[
                                "navigation__item",
                                link.click && "navigation__item-active"
                            ].join(" ")}
                        >
                            {link.name}
                        </li>
                    ))}
                    <div className="header__user-info">
                        <Button onClick={addListinghandler}>Add Property</Button>
                        {/* <Button onClick={updateListinghandler}>Update Property</Button>
                        <ButtonSecondary onClick={logoutHandler}>Logout</ButtonSecondary> */}
                        {/* <Avatar className="navbar__image" /> */}
                        <IconButton
                            aria-label="more"
                            aria-controls="long-menu"
                            aria-haspopup="true"
                            onClick={handleClick}
                        >
                            <SettingsIcon fontSize="large" />
                        </IconButton>
                        <Menu
                            id="long-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={open}
                            onClose={handleClose}
                            PaperProps={{
                                style: {
                                    minWidth: '8rem',
                                },
                            }}
                        >
                            <MenuItem onClick={updateListinghandler}>Update Property</MenuItem>
                            <MenuItem onClick={logoutHandler}>Logout</MenuItem>
                        </Menu>
                    </div>
                </ul>
                {/* <div>
            <Avatar />
          </div> */}
                <div onClick={toggleClickHandler} className="navbar__toggle-menu">
                    <IconButton size='medium'>{showMenu ? <CloseIcon style={iconStyles} /> : <MenuIcon style={iconStyles} />}</IconButton>
                </div>
            </nav>
        </header>
    );
};

export default Header;
