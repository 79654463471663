import React, { useEffect } from 'react'
import './App.css';
import Header from '../header2/Header';
import { Switch, Route, useHistory } from 'react-router-dom'
import Main from '../main/Main';
import MainPage from '../main-page/MainPage'
import Login from '../login/Login'
import SignUp from '../signup/SignUp'
import PageNotFound from '../error/PageNotFound'
import RegisterProperty from '../registerProperty/RegisterProperty'
import UserProperties from '../user-properties/UserProperties'
import TermsAndConditions from '../terms-conditions/TermAndConditions'
import ResetPassword from '../reset-password/ResetPassword';
import Preview from '../user-properties/preview/Preview'
import Booking from '../booking/Booking';
import Help from '../help/Help';
import Calender from '../calender/Calender';
// import Chat from '../chat/Chat'

function App() {
  
  const history = useHistory();

  useEffect(() => {
    // Get current user
    if (localStorage.getItem('userId')) {
      history.push('/dashboard')
    }
    // history.push('/dashboard')
    return () => {

    }
  }, [])
  // async function checkUser() {

  //   const userRef = db.collection('testUsers');
  //   const snapshot = await userRef.where('userId', '==', userId).get();
  //   if (snapshot.empty) {
  //     console.log('No matching documents.');
  //     return;
  //   }

  //   snapshot.forEach(doc => {
  //     // console.log(doc.id, '=>', doc.data());
  //     localStorage.setItem('docId', doc.id)
  //     return;
  //   });
  // }
  // checkUser()

  /*useEffect(() => {
    authListener()
  }, [])

  function authListener() {
    auth.onAuthStateChanged((user) => {
      console.log(user);
      if (user) {
        localStorage.setItem("user", user.uid);
      } else {
        localStorage.removeItem("user");
      }
    });
  }*/

  return (
    <div className="app">
      <Switch >
        {/* <Route path="/chat">
          <Header />
          <Chat />
        </Route> */}
        <Route exact path="/">
          <MainPage />
        </Route>
        <Route path="/dashboard">
          <Header />
          <Main className="container" />
        </Route>
        <Route path="/register">
          <RegisterProperty />
        </Route>
        <Route path="/preview-property/:propertyId">
          <Preview />
        </Route>
        <Route exact path="/my-properties">
          <Header />
          <UserProperties />
        </Route>
        <Route path="/bookings">
          <Header />
          <Booking />
        </Route>
        <Route path="/calendar">
          <Header />
          <Calender />
        </Route>
        <Route exact path="/privacy-policy">
          <TermsAndConditions />
        </Route>
        <Route exact path="/help">
        <Header />
          <Help />
        </Route>
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/reset-password">
          <ResetPassword />
        </Route>
        <Route path="/signup">
          <SignUp />
        </Route>
        <Route path="*">
          <PageNotFound />
        </Route>
      </Switch>
    </div>
  );
}

export default App;
