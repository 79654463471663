import firebase from 'firebase'
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: "AIzaSyDJKaugGnx3iwpLPL_6XQZ_ntCL3Ycd3YU",
  authDomain: "musafyr-2bad9.firebaseapp.com",
  projectId: "musafyr-2bad9",
  storageBucket: "musafyr-2bad9.appspot.com",
  messagingSenderId: "794650961412",
  appId: "1:794650961412:web:9da782edcb157d151bb751"
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const db = firebaseApp.firestore();

const projectStorage = firebase.storage();

const auth = firebase.auth() // for google authentication
const provider = new firebase.auth.GoogleAuthProvider()

const databaseUserName = 'users'
const databasePropertyName = 'properties'

export {auth,provider,projectStorage,databaseUserName,databasePropertyName};// will use later
export default db;