import React from "react";
import { Link } from "react-router-dom";
import classes from "./Footer.module.css";

const Footer = () => {
  return (
    <div className={classes.footer}>
      <p className={classes.footer__copyright}>
        Copyright &#169; Musafyr 2020-2021 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <Link to="privacy-policy">Privacy Policy</Link>
      </p>
    </div>
  );
};

export default Footer;
