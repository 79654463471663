import React,{useState} from 'react'
import Hr from '../UI/hr/Hr'
import './Calender.css'
import DatePicker from './date-picker/DatePicker'

const Calender = () => {

    const [dates, setDates] = useState([])

    const dateClickHandler = (dates) => {

        setDates(dates)
    }
    // console.log('dates >>> ',dates)
    return (

        <div className="calender container">
            {/* <h1>Calender</h1> */}
            <div className="calender__date-picker">
                <DatePicker dateClickHandler={dateClickHandler} />
            </div>
            <Hr />
            <div className="calender__closed-dates">
                <h3>Selected Dates</h3>
                <ul>
                    {dates.length ? dates.map( (date,i) => <li key={i}>{date.toLocaleDateString()}</li>):null}
                </ul>

            </div>
        </div>
    )
}

export default Calender
